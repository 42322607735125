// src/components/ChatWindow/ChatWindow.js
import { ArrowCircleLeft, ArrowLeftSharp, ChatBubbleOutline as ChatBubbleOutlineIcon } from "@mui/icons-material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"; // Importing the icon
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FaceIcon from "@mui/icons-material/Face";
import RobotIcon from "@mui/icons-material/SmartToy";
import {
  Avatar,
  Button,
  Container,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { marked } from "marked";
import React, { useEffect, useRef, useState } from "react";
import LONG_RESPONSE_MESSAGE from "../../App.js";
import { useMobile } from "../../contexts/MobileContext";
import { StartSpeedDial } from "../StartSpeedDial/StartSpeedDial";
import { LoadingIndicator } from "./LoadingCreateIndicator.js";
import { NewThreadEncourager } from "./NewThreadEncourager.js";
import TypingIndicator from "./TypingIndicator";

const ChatWindow = ({
  messages,
  sendMessage,
  currentMessage,
  setCurrentMessage,
  setMessages,
  thread,
  setCurrentThread,
  isTyping,
  lastMessageSender,
  loadingCreateThread,
  theme,
  isAdmin,
  onNewThreadClick,
  onStartQuizClick,
  coursesData,
  drawerOpen,
  setDrawerOpen,
  isLongAwait,
}) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef(null);
  const [textFieldHeight, setTextFieldHeight] = useState(0);
  const textFieldRef = useRef(null);
  const listRef = useRef(null);
  const isMobile = useMobile().isMobile;
  const isiOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const DISABLED_COURSES = ["UTCH320N"];
  const [buttonBottomPosition, setButtonBottomPosition] = useState(0);

  const ChatSpeedDialStyle = {
    position: "absolute",

    right: (theme) => theme.spacing(4.5), // Adjust right positioning for consistency
  };

  const scrollToBottom = () => {
    const element = listRef.current;
    if (!element) return;

    const targetPosition = element.scrollHeight - element.clientHeight; // Destination
    const startPosition = element.scrollTop; // Starting position
    const distance = targetPosition - startPosition; // Total scroll distance
    const duration = 500; // Duration of the animation in milliseconds
    let startTime = null;

    const scroll = (currentTime) => {
      if (startTime === null) {
        startTime = currentTime;
      }
      const timeElapsed = currentTime - startTime;
      const nextScrollPosition = easeInOutQuad(
        timeElapsed,
        startPosition,
        distance,
        duration
      );

      element.scrollTop = nextScrollPosition;

      if (timeElapsed < duration) {
        requestAnimationFrame(scroll);
      }
    };

    requestAnimationFrame(scroll);
  };

  function easeInOutQuad(t, b, c, d) {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  }

  const handleScroll = () => {
    // Check if the user has scrolled up a significant amount
    if (listRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listRef.current;
      setShowScrollButton(scrollTop < scrollHeight - clientHeight - 400);
    }
  };

  const [isSending, setIsSending] = useState(false);

  const handleSend = async (currentMessage) => {
    setIsSending(true);
    if (!thread["current"] || Object.keys(thread["current"]).length === 0) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: [{ text: currentMessage }], role: "user" },
      ]);
      setCurrentMessage("");
      await onNewThreadClick(currentMessage);
    }

    sendMessage();
    setButtonBottomPosition(0);
    window.scrollTo(0, 0);
    setIsSending(false);
  };

  const onSpeedDialClick = () => {
    setCurrentThread({});
    thread["current"] = null;
    setMessages([]);
  };

  const handleInputBlur = () => {
    window.scrollTo(0, 0);
  };

  const handleInputFocus = () => {
    if (drawerOpen) {
      setDrawerOpen(false);
    }
  };

  const processContentForDisplay = (content) => {
    if (!content) {
      return "";
    }
    const latexMatches = [];
    let placeholderCounter = 0;
    const text = content[0]["text"];
    if (!text) {
      return "";
    }

    // Replace LaTeX with placeholders
    let modifiedText = text.replace(
      /\\\[(.*?)\\\]|\\\((.*?)\\\)/gs,
      (match) => {
        const placeholder = `{{LATEX_PLACEHOLDER_${placeholderCounter++}}}`;
        latexMatches.push(match); // Store the original LaTeX
        return placeholder; // Replace LaTeX with a placeholder
      }
    );
    // Assuming `marked` is available and `text` is your input Markdown with placeholders
    let html = marked(modifiedText);
    // Replace each placeholder with its corresponding LaTeX
    latexMatches.forEach((latex, index) => {
      const placeholder = `{{LATEX_PLACEHOLDER_${index}}}`;
      html = html.replace(placeholder, latex);
    });

    // remove citation placeholders (anything between 【】) if they exist
    html = html.replace(/【.*?】/g, "");

    // Return processed content as HTML string
    return html;
  };

  useEffect(() => {
    if (lastMessageSender === "user") {
      scrollToBottom();
    }
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
    // Attach the scroll event listener
    const listEl = listRef.current;
    if (listEl) {
      listEl.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (listEl) {
        listEl.removeEventListener("scroll", handleScroll);
      }
    };
  }, [messages, lastMessageSender]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setTextFieldHeight(entry.target.clientHeight);
      }
    });

    if (textFieldRef.current) {
      observer.observe(textFieldRef.current);
    }

    return () => {
      if (textFieldRef.current) {
        observer.unobserve(textFieldRef.current);
      }
    };
  }, []);
  useEffect(() => {}, [thread]);

  return (
    <>
      <Container
        maxWidth={false}
        sx={{
          height: isMobile ? "calc(100vh - 100px)" : "calc(100vh - 50px)",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          flexShrink: 1,
          justifyContent: "space-between",
          padding: "10px",
          zIndex: -1,
        }}
      >
        <List
          ref={listRef}
          sx={{
            overflowY: "auto",
            overflowX: "hidden",
            flexGrow: 1,
            mb: 2,
            px: isMobile ? 2 : 3,
            width: "100%",
            maxWidth: "100%",
            alignSelf: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {messages.length === 0 && (
            <Container
              maxWidth="sm"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                textAlign: "center",
                padding: 4,
              }}
            >
              <ChatBubbleOutlineIcon fontSize="large" color="action" />
              <Typography variant="h6" color="textSecondary" mt={2}>
                No messages yet
              </Typography>
              {isAdmin ? (
                <>
                  <Typography variant="body1" color="textSecondary" mt={1}>
                    The student has not sent a message here.
                  </Typography>
                  <Typography variant="body1" color="textSecondary" mt={1}>
                    Select a different conversation from the sidebar.
                  </Typography>
                  <ArrowCircleLeft color="action"
                  sx={{
                    fontSize: 80,
                    marginTop: 5,
                  }}
                  />
                </>
              ) : (
                <>
                  <Typography variant="body1" color="textSecondary" mt={1}>
                    Start a new conversation by sending your first message.
                  </Typography>
                  <ArrowDownwardIcon
                    color="action"
                    sx={{
                      fontSize: 80,
                      marginTop: 10,
                    }}
                  />
                </>
              )}
            </Container>
          )}
          {messages.map((msg, index) => (
            <ListItem
              key={index}
              style={{
                display: "flex",
                justifyContent: msg.role === "user" ? "flex-end" : "flex-start",
                width: "100%",
                maxWidth: "60%",
                padding: 10,
                marginLeft: msg.role === "user" ? "-9.5%" : "0",
                marginRight: msg.role === "assistant" ? "-9.5%" : "0",
              }}
            >
              <Paper
                style={{
                  padding: "10px 20px",
                  borderRadius: "20px",
                  backgroundColor:
                    msg.role === "user"
                      ? theme.palette.primary.main
                      : theme.palette.background.paper,
                  color:
                    msg.role === "user"
                      ? theme.palette.primary.contrastText
                      : theme.palette.text.primary,
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  maxWidth: "80%",

                  marginBottom: "2px",
                  boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                }}
              >
                {msg.role === "assistant" && (
                  <Avatar
                    style={{
                      backgroundColor: theme.palette.secondary.main,
                    }}
                  >
                    <RobotIcon />
                  </Avatar>
                )}
                {msg.isTyping ? (
                  <TypingIndicator
                    theme={theme}
                    message={isLongAwait ? LONG_RESPONSE_MESSAGE : ""}
                  />
                ) : (
                  <ListItemText
                    primary={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: processContentForDisplay(msg.content),
                        }}
                      />
                    }
                  />
                )}
                {msg.role === "user" && (
                  <Avatar
                    style={{ backgroundColor: theme.palette.primary.main }}
                  >
                    <FaceIcon />
                  </Avatar>
                )}
              </Paper>
            </ListItem>
          ))}
          {loadingCreateThread && <LoadingIndicator />}
          <div ref={messagesEndRef} />{" "}
        </List>
        {showScrollButton && !isMobile && (
          <IconButton
            onClick={scrollToBottom}
            sx={{
              position: "fixed",
              right: (theme) =>
                isAdmin ? theme.spacing(6.25) : theme.spacing(12.5),
              bottom: (theme) =>
                `calc(${theme.spacing(2.5)} + ${textFieldHeight}px)`,
              backgroundColor: "primary.main",
              color: "primary.contrastText",
              borderRadius: "50%",
              "&:hover": {
                backgroundColor: "primary.dark",
              },
              zIndex: 1500,
            }}
          >
            <ArrowDownwardIcon />
          </IconButton>
        )}
        {!isAdmin && coursesData.length > 0 && (
          <StartSpeedDial
            onSpeedDialClick={onSpeedDialClick}
            onStartQuizClick={onStartQuizClick}
            sx={{
              ...ChatSpeedDialStyle,
              bottom: (theme) =>
                isMobile
                  ? `calc(${theme.spacing(10)} + ${textFieldHeight}px)`
                  : `calc(${theme.spacing(2.5)} + ${textFieldHeight}px)`,
            }}
          />
        )}

        {messages.length >= 10 && <NewThreadEncourager />}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            position: "relative",
            borderTop: "1px solid #ddd",
            borderBottom: "1px solid #ddd",
            paddingTop: "10px",
            width: "100%",

            paddingBottom: isiOS
              ? `calc(env(safe-area-inset-bottom) + 64px)`
              : "10px",
          }}
        >
          {/*  && */}
          {!isAdmin &&
            (DISABLED_COURSES.includes(thread.courseId) ? (
              <>
                <TextField
                  label={`The pilot for ${thread.courseId} has ended, but we hope to return soon. Thanks for using Tailored Tutor!`}
                  position="fixed"
                  fullWidth
                  disabled="true"
                  style={{ marginRight: "10px" }}
                  InputProps={{
                    style: { color: theme.palette.text.input },
                  }}
                />
                <Button
                  onClick={handleSend}
                  color="primary"
                  variant="contained"
                  disabled="true"
                >
                  Send
                </Button>
              </>
            ) : (
              <>
                <TextField
                  ref={textFieldRef}
                  label="Type a message"
                  position="relative"
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  fullWidth
                  value={currentMessage}
                  onChange={(e) => {
                    setCurrentMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && e.shiftKey) {
                      e.preventDefault();
                      setCurrentMessage(currentMessage + "\n");
                    } else if (e.key === "Enter" && !isTyping) {
                      e.preventDefault();
                      handleSend(currentMessage);
                    }
                  }}
                  multiline
                  maxRows={3}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ position: "absolute", bottom: 24, right: 16 }}
                      >
                        <IconButton
                          aria-label="Send Message Button"
                          onClick={handleSend}
                          disabled={!currentMessage.trim()}
                          edge="end"
                          color="primary"
                        >
                          <ArrowUpwardIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    style: {
                      color: theme.palette.text.input,
                      height: "auto",
                      overflow: "visible",
                      display: "flex",
                      flexDirection: "column-reverse",
                    },
                    inputProps: {
                      style: {
                        height: "auto",
                        overflow: "visible",
                        display: "flex",
                        flexDirection: "column-reverse",
                      },
                    },
                  }}
                  variant="outlined" // or "filled" or "standard"
                  style={{
                    marginRight: "10px",
                    height: "auto",
                    overflow: "visible",
                  }}
                  disabled={isSending || isTyping}
                />
              </>
            ))}
        </div>
      </Container>
    </>
  );
};

export default ChatWindow;
